@import url('https://fonts.googleapis.com/css2?family=Orbitron');

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}
#root{
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.table-header-sort-label {
	border: none !important;
	padding: 1px !important;
	font-weight: bold !important;
}

.MuiTableCell-alignRight.table-header-sort-label>div{
	padding-right: 29px !important;
}

.MuiTableCell-alignLeft.table-header-sort-label>div{
	padding-left: 29px !important;
}

td.MuiTableCell-alignLeft {
	padding-left: 29px !important;
}

td.MuiTableCell-alignRight {
	padding-right: 29px !important;
}

.table-header-sort-padding{
	border: none !important;
	padding: 1px !important;
	font-weight: bold !important;
	padding-left: 29px !important;
}

.table-header-separator{
	border-right: 2px solid #1976d242 !important;
}

.table-space-reject {
	padding-left : 0 !important; 
	padding-right: 0 !important;
}

.table-header {
	border-left: 2px solid #1976d242 !important;
	margin-left: -2px !important;
	font-weight: bold;
}

.table-header-sort-label>span {
	font-weight: bold;
}

.table-header-sort-padding>span {
	font-weight: bold;
}

.content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
}

fieldset {
	color: #1976d2;
	border: 1px solid grey;
	border-radius: 7px;

}

.MuiTablePagination-toolbar{
	min-height: auto;
	height: 40px;
}