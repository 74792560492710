/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {

  border-radius: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #1976d2ab; 
  
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1976d2; 
  border: 1px solid #012f5e;
}
fieldset {
    border-radius: 0px;
}