.MuiTabs-flexContainer {
	height: 100%;
}
.MuiTabPanel-root {
	box-shadow: 1px 1px 2px 1px rgb(25 118 210 / 85%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.Mui-selected {
	font-weight: bold !important;
}
