.imagen {
	width: 15px;
	/* height: 100%; */
	white-space: nowrap;
	align-items: center;
}

#main-div {
	background-color: white;
	color: black;
	align-items: center;
	width: 100%;
	height: 35px;
	padding-top: 3px;
	text-align: center;
}
